import React, { useState, useEffect } from "react";
import PostalMime from "postal-mime";
import crypto from "crypto-browserify";
import { styles } from "./styles";

const EmailRenderer = () => {
  const [emailBody, setEmailBody] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [bhContent, setBhContent] = useState("");
  const [bhValue, setBhValue] = useState("");
  const [highlightEnabled, setHighlightEnabled] = useState(false);
  const [highlightedText, setHighlightedText] = useState([]);
  const [showEMLPreview, setShowEMLPreview] = useState(false); // this will alter between raw eml and its preview

  useEffect(() => {
    const handleSelection = () => {
      const selection = window.getSelection();
      if (selection.rangeCount > 0 && selection.toString().length > 0) {
        const selectedText = selection.toString();
        setHighlightedText((prev) => [...prev, selectedText]);
      }
    };

    if (highlightEnabled) {
      document.addEventListener("mouseup", handleSelection);
    } else {
      document.removeEventListener("mouseup", handleSelection);
    }

    return () => {
      document.removeEventListener("mouseup", handleSelection);
    };
  }, [highlightEnabled]);

  const handleFileUpload = async (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const content = event.target?.result;
        setFileContent(content);
        await parseEml(content);
        extractBhValue(content);
      };
      reader.readAsText(file);
    }
  };

  const handleTextAreaChange = async (e) => {
    if (!e.target.value) {
      setFileContent("");
      return;
    }
    const content = e.target.value;
    setFileContent(content);
    await parseEml(content);
    extractBhValue(content);
  };

  const parseEml = async (emlContent) => {
    const parser = new PostalMime();
    const parsedEmail = await parser.parse(emlContent);
    setEmailBody(
      parsedEmail.html || parsedEmail.text || "No body content found"
    );
  };

  const extractBhValue = (emlContent) => {
    const bhRegex = /bh=([^;]+)/;
    const match = emlContent.match(bhRegex);
    if (match) {
      const bh = match[1];
      setBhValue(bh);
      calculateBhContent(emlContent, bh);
    } else {
      setBhContent("bh value not found");
    }
  };

  const calculateBhContent = (emlContent, bh) => {
    const bodyContent = extractBodyContent(emlContent);
    if (bodyContent) {
      const normalizedContent = normalizeBodyContent(bodyContent);
      const hash = crypto
        .createHash("sha256")
        .update(normalizedContent)
        .digest("base64");
      setBhContent(hash === bh ? normalizedContent : `Hash value: ${hash}`);
    } else {
      setBhContent("Body content not found");
    }
  };

  const extractBodyContent = (emlContent) => {
    const bodyRegex = /\r?\n\r?\n([\s\S]*)/;
    const match = emlContent.match(bodyRegex);
    return match ? match[1] : null;
  };

  const normalizeBodyContent = (bodyContent) => {
    const trimmedContent = bodyContent.replace(/\s+$/, "");
    const crlfContent = trimmedContent.replace(/\r?\n/g, "\r\n");
    const normalizedContent = crlfContent.replace(/(\r\n)*$/, "\r\n");
    return normalizedContent;
  };
  const getUtf8BytesWithZeroes = (content) => {
    const utf8Encoder = new TextEncoder();
    const bytes = utf8Encoder.encode(content);

    const ranges = [];

    highlightedText.forEach((highlightedString) => {
      let startIndex = 0;
      while (startIndex < content.length) {
        const index = content.indexOf(highlightedString, startIndex);
        if (index === -1) break;
        ranges.push({ start: index, end: index + highlightedString.length });
        startIndex = index + highlightedString.length;
      }
    });

    ranges.forEach(({ start, end }) => {
      for (let i = start; i < end; i++) {
        bytes[i] = 0;
      }
    });

    return bytes;
  };

  const renderBytes = (bytes) => {
    const byteArray = Array.from(bytes).map((byte) => (byte === 0 ? "0" : "1"));
    return `[${byteArray.join(", ")}]`;
  };

  const getHighlightedContent = (content) => {
    if (highlightedText.length === 0) return content;
    
    // Combine all highlight phrases into one regex
    const combinedRegex = new RegExp(
      `(${highlightedText.join('|')})`,
      'gi'
    );
  
    // Split the content based on the combined regex and wrap matches in <span> elements
    const highlightedContent = content.split(combinedRegex).map((part, index) => 
      combinedRegex.test(part) ? (
        <span className="highlight" key={`highlight-${index}`}>
          {part}
        </span>
      ) : (
        part
      )
    );
  
    return highlightedContent;
  };
  
  const getHighlightedContentHTML = (content) => {
    if (highlightedText.length === 0) return content;

    let highlightedContent = content;

    highlightedText.forEach((text) => {
      // Match the highlighted text only if it's not inside HTML tags
      const regex = new RegExp(`(?!<[^>]*?)(${text})(?![^<]*?>)`, "gi");
      highlightedContent = highlightedContent.replace(
        regex,
        '<span class="highlight">$1</span>'
      );
    });

    return highlightedContent;
  };

  return (
    <div style={styles.container}>
      <h1>Email Renderer</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "3rem 5rem",
          minWidth: "70vw",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        {/* <input
            type="file"
            accept=".eml"
            onChange={handleFileUpload}
            style={styles.input}
          /> */}
        <div class="file-input-container">
          <label for="file-upload" class="file-input-label">
            Choose a file
          </label>
          <input
            id="file-upload"
            class="file-input"
            type="file"
            accept=".eml"
            onChange={handleFileUpload}
          />
        </div>

        <textarea
          value={fileContent}
          onChange={handleTextAreaChange}
          rows={5}
          placeholder="Or paste .eml content here"
          className="textarea-floating"
        />
      </div>
      {fileContent ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <button
            onClick={() => setHighlightEnabled(!highlightEnabled)}
            className="button"
          >
            {highlightEnabled ? "Disable Highlighter" : "Enable Highlighter"}
          </button>
          <button
            onClick={() => setShowEMLPreview(!showEMLPreview)}
            className="button"
          >
            {showEMLPreview ? "Switch to Raw EML" : "Switch to EML Preview"}
          </button>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "50vh",
          margin: "1rem",
        }}
      >
        {showEMLPreview && bhContent ? (
          <div style={styles.rawContent}>
            <h2>Content used for bh</h2>

            <pre>{getHighlightedContent(bhContent)}</pre>
          </div>
        ) : (
          <div
            style={styles.emailBody}
            dangerouslySetInnerHTML={{
              __html: getHighlightedContentHTML(emailBody),
            }}
          />
        )}
      </div>
      {/* {bhValue && (
        <div style={styles.bhValue}>
          <h2>Extracted bh Value</h2>
          <p>{bhValue}</p>
        </div>
      )} */}
      {/* {highlightedText.length ? (
        <div style={styles.highlightedContent}>
          <h2>Content with Highlighted Bytes Set to Zero</h2>
          <div style={styles.bytesContainer}>
            {renderBytes(getUtf8BytesWithZeroes(bhContent))}
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default EmailRenderer;
